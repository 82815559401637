
<template>
  <section class="governService">
    <div class="itemContainer">
          <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide class="item-device"  v-for="(item, index) in news" :key="index">
                  <div class="device" @click="goDetail(item.id)" v-if="item.url">
                      <div class="dev-img" style="width:100%">
                          <img v-if="item.url" :src="item.url"  style="width:100%;height:auto"
                      >

                      <span v-if="!item.url">暂无图片</span>
                      </div>
                       <h1 class="desc">{{item.text}}</h1>
                  </div>
              </swiper-slide>
              <div class="swiper-button-prev" ref="prevBtn"  slot="button-prev">
                 <i class="el-icon-arrow-left"></i>
              </div>
              <div class="swiper-button-next"   ref="nextBtn"  slot="button-next">
                <i class="el-icon-arrow-right"></i>
              </div>
         </swiper>
    </div>
    <div class="btnContainer">
      <div class="btn-wrap">
          <div class="button button1"  @click="leftClick"> <i class="el-icon-arrow-left"></i></div>
        <div class="button button2" @click="rightClick"> <i class="el-icon-arrow-right"></i></div>
      </div>
      <!-- <div class="button1" @click="leftClick"><img src="@/assets/social/per.png" alt=""></div>
      <div class="button2"  @click="rightClick"><img src="@/assets/social/next.png" alt=""></div> -->
    </div>
  </section>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'ArrowCur',
  props:{
    news:{
      type:Array,
      default:function(){return []}
    }
  },
  components: {
     swiper, 
    swiperSlide
  },
  data() {
    return {
       swiperOptions: {
                // slidesOffsetBefore: 10,
                // slidesOffsetAfter: 10,
                // slidesPerView: '4', // 可见数量
              
                // spaceBetween: 15,
                // history: 'love',
                // pagination: {
                //         el: '.swiper-pagination',
                //   },
                // centeredSlides: true,
                // loop: true,
                freeMode: true,
                slidesPerView: 4,
                paginationClickable: true,
                spaceBetween: 30,
                loop: true,
                 navigation: {
                    nextEl: '.swiper-button-prev',
                    prevEl: '.swiper-button-next',
               },

               preventClicks : false,//默认true
                  
        },
      examples: [
        {
          url: require("@/assets/comDynamics/01.png"),
          text: "北京市委预付式消费信用监管和服务工作专题专题调研会"
        },
        {
          url: require("@/assets/comDynamics/01.png"),
          text: "全国政法智能化建设技术装备及成果展"
        },
        {
          url: require("@/assets/comDynamics/01.png"),
          text: "商务部市场体系建设司来我司调研"
        },
        {
          url: require("@/assets/comDynamics/01.png"),
          text: "北京市人大预付式消费立法和资金监管专题调研会"
        },
        {
          url: require("@/assets/comDynamics/01.png"),
          text: "北京市人大预付式消费立法和资金监管专题调研会2"
        }
      ]
    }
  },
  created() {

  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
  },
  methods: {
    // 路由跳转
    goDetail(id){
      this.$router.push({
				path: "/DynamicDetail",
				query: { id:id },
			});
    },
    leftClick() {
        this.$refs.nextBtn.click();
    },
      rightClick() {
        this.$refs.prevBtn.click();
    },
  }
}
</script>

<style lang="scss" scoped>
.governService {
  width:14.32rem;
  // width:12rem;
  margin:0 auto;
  position: relative;
  top:-0.96rem;
  .itemContainer {
    width: 14.32rem;
    overflow: hidden;
    // height: 3380rem;
    position: relative;
    //  border:1px solid red;

      /deep/.swiper-wrapper{
        height: 3.5rem !important;
      }

      /deep/ .swiper-button-prev, /deep/ .swiper-button-next{
          visibility: hidden;
      }


      .device{
        cursor: pointer;
        // height:3.62rem !important ; 
        padding-bottom: 0.4rem;
        display: flex;
        flex-direction: column;
         background: #FFFFFF;
        border: 0.01rem solid #FFFFFF;
        box-shadow: 0 0.04rem 0.12rem 0 rgba(0,15,67,0.10);
        border-radius: 0.08rem;
        box-sizing: border-box;
        .dev-img{
          // height:2.3rem;
          // height:calc(100% - 1.32rem);
          overflow: hidden;
          display: flex;
          align-items: center;
           /* width: 100%; */
          padding-bottom: 56%;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;

          img{
            // height:100% !important;
            width: 100% !important;;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
          }
        }
      }

  }


  .carouselContainer:nth-child(1){
    left: 0.10rem !important;
  }
  .desc {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-top: 0.30rem;
    // width: 2.42rem;
    /* margin: 46rem auto; */
    color: #1c1c1c;
    line-height: 0.27rem;
    font-weight: 400;
    text-align: center;
    font-size: 0.18rem;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;


  }
  .carouselContainer {
    position: absolute;
    transition: all 1s !important;
    width: 2.82rem;
    height: 3.54rem;
    background: #FFFFFF;
    border: 0.01rem solid #FFFFFF;
    box-shadow: 0 0.04rem 0.12rem 0 rgba(0,15,67,0.10);
    border-radius: 8rem;
    img {
      width: 2.82rem;
    }
    h1,.desc {
      width: 2.42rem;
      margin: 0.38rem auto;
      color: #1c1c1c;
      line-height: 0.27rem;
      font-weight: 400;
      font-size: 0.18rem;
    }
  }
  .btnContainer {
   width: 100%;
    color: #000;
    position: absolute;
    top: 3.5rem;
    z-index: 999;
    cursor: pointer;
    display: flex;
    justify-content: center;
    .btn-wrap{
      width:1.40rem;
      display: flex;
    justify-content: space-between;
    }
     .button1{
        z-index: 10;
        left: -0.30rem;
        top: -1.32rem;
        color: #fff;

      }
      .button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.51rem;
        height: 0.51rem;
        background: rgba(3,54,191,0.06);
        border-radius: 50%;
        color:#0336BF;
        i{
          font-size: 0.26rem;
        }

      }
      .button2{
        top: -1.32rem;
        right: -0.25rem;
        z-index: 20;
      }
  }
}
/* fade */
.fade-enter-active {
  transform: scale(1.3);
  box-shadow: 0 0 0.10rem #ffffff;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter {
  transform: scale(1);
}
.fade-leave-active {
  transform: scale(0.6);
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-0.30rem);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(0.30rem);
}
</style>
